import { useRouter } from 'next/router'
import { Button } from '@unholster/seisveinte'
import { Result as ResultBase } from 'antd'
import React from 'react'
import styled from 'styled-components'

import { Error400Svg } from '../components/Icons'

import 'antd/dist/antd.css'

const { FilledButton } = Button

export default () => {
  const router = useRouter()

  const redirectHome = () => {
    router.push('/')
  }

  return (
    <Wrapper>
      <Error400 />
      <Result
        extra={<FilledButton onClick={redirectHome}>Volver a la página principal</FilledButton>}
        icon={<div />}
        subTitle="La página que estas visitando no existe"
        title="404"
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Result = styled(ResultBase)`
  align-items: center;
  width:fit-content ;
  padding: 0px;
`
const Error400 = styled(Error400Svg)`
  max-width: 550px;
`
